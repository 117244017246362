<template>
  <div class="bg-white w-full p-3">
    <TemplatesTable />
  </div>
</template>

<script>
import TemplatesTable from "@/modules/xpbx/components/settings/TemplatesTable/TemplatesTable.vue";

export default {
  name: "Templates",

  components: {
    TemplatesTable,
  },
};
</script>
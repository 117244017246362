<template>
  <!-- Templates table -->
  <section class="w-full h-full">
    <PageHeader
      :heading="$t('xpbx.settings.templates.heading')"
      :showButton="false"
    />

    <!-- Templates table -->
    <div class="card relative table-wrapper">
      <!-- Table -->
      <DataTable
        ref="dt"
        selectionMode="single"
        dataKey="id"
        :paginator="true"
        :rows="10"
        scrollable
        :scrollHeight="`${scrollHeight}px`"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        :value="templates"
        filterDisplay="menu"
        paginatorPosition="top"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} templates"
        :rowsPerPageOptions="[5, 10, 25]"
        v-if="!loading"
        :globalFilterFields="['description']"
      >
        <template #empty>
          {{ $t("xpbx.settings.fields.empty_table") }}</template
        >
        <template #header>
          <div class="flex gap-2 align-items-center justify-between">
            <div>
              <Button
                :label="$t('xpbx.settings.templates.buttons.new')"
                class="mr-2 add-record-button"
                @click="openNew"
              />
            </div>
            <div class="flex gap-2 items-center">
              <InputText
                class="search-input"
                v-model="filters['global'].value"
                :placeholder="$t('xpbx.settings.templates.placeholders.search')"
              />
              <Export
                :dt="dt"
                :tableData="templates"
                :columns="temlateHeaders"
              />
            </div>
          </div>
        </template>
        <Column
          sortable
          field="description"
          :header="$t('xpbx.settings.templates.fields.name')"
        >
          <template #body="{ data }">
            {{ data?.description }}
            <div>
              <TableActions
                :data="data"
                :id="data.id"
                @edit="editHandle"
                @setActive="setActive"
                :isActive="data.is_active"
                @delete="deleteSelectedRecord"
              />
            </div>
          </template>
        </Column>
        <Column
          sortable
          field="message"
          :header="$t('xpbx.settings.templates.fields.content')"
        >
          <template #body="{ data }">
            {{ data?.message }}
          </template>
        </Column>
        <Column
          sortable
          field="channel"
          :header="$t('xpbx.settings.templates.fields.channel')"
        >
          <template #body="{ data }">
            {{ data?.channel }}
          </template>
        </Column>
      </DataTable>
      <Loader v-else />
    </div>

    <!-- Dialogs -->
    <Dialog
      v-model:visible="deleteRecordsDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.description">{{
          $t("xpbx.settings.templates.notification.confirm_delete", {
            delete: `template ${selectedRecord?.description}`,
          })
        }}</span>
      </div>
      <template #footer>
        <Button
          text
          @click="deleteRecordsDialog = false"
          :label="$t('xpbx.settings.templates.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.templates.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="templateDialog"
      :style="{ width: '450px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.templates.headings.edit_template')
          : $t('xpbx.settings.templates.headings.create_template')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!-- Channels -->
      <FormDropdown
        optionLabel="name"
        :options="templatePeriods"
        :error="errors?.channel?.[0]"
        v-model:modelValue="template.channel"
        :label="$t('xpbx.settings.templates.labels.channel')"
        :hint="$t('xpbx.settings.templates.hints.channel')"
      />

      <!-- Template name -->
      <FormInput
        id="description"
        :error="errors?.description?.[0]"
        v-model:modelValue="template.description"
        :label="$t('xpbx.settings.templates.labels.template_name')"
        :hint="$t('xpbx.settings.templates.hints.description')"
      />

      <!-- Content -->
      <FormTextArea
        id="message"
        :error="errors?.message?.[0]"
        v-model:modelValue="template.message"
        :label="$t('xpbx.settings.templates.labels.content')"
        :hint="$t('xpbx.settings.templates.hints.message')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="templateDialog = false"
        />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useTemplates from "@/modules/xpbx/composables/useTemplates";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Textarea from "primevue/textarea";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import FormTextArea from "@/modules/xpbx/components/forms/FormTextArea.vue";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { validateTemplate } from "@/composables/auth/templateValidations";
import { temlateHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "TemplatesTable",

  components: {
    PageHeader,
    Loader,
    DataTable,
    Column,
    Button,
    InputText,
    Export,
    Dialog,
    Textarea,
    FormInput,
    FormDropdown,
    FormTextArea,
    DialogButtons,
    TableActions,
  },

  setup() {
    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const templateDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const {
      findTemplates,
      deleteTemplate,
      createTemplate,
      updateTemplate,
      loading,
      templates,
      template,
    } = useTemplates();

    const editHandle = (data) => {
      template.value = {
        ...data,
        channel: { value: data.channel, name: data.channel },
      };
      isEdit.value = true;
      templateDialog.value = true;
    };

    const resetForm = () => {
      template.value = {
        description: "",
        message: "",
        channel: { value: "SMS", name: "SMS" },
      };
      clearErrors();
    };

    // Data Table Methods
    const openNew = () => {
      resetForm();
      isEdit.value = false;
      templateDialog.value = true;
    };

    const reset = () => {
      resetForm();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const closeDialog = (value) => {
      templateDialog.value = value;
    };

    const create = async () => {
      submitted.value = true;
      const isValid = validateTemplate(template.value);
      console.log("isValid", isValid);

      const formData = {
        ...template.value,
        channel: template.value?.channel?.value
          ? template.value.channel.value
          : template.value.channel || "",
      };

      if (isValid) {
        if (isEdit.value === true) {
          await updateTemplate(formData, template.value.id);
        } else {
          await createTemplate(formData);
        }

        isEdit.value = false;
        templateDialog.value = false;
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = async (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteTemplate(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const setActive = async (data) => {
      const formData = {
        ...data,
        is_active: data.is_active === 1 ? 0 : 1,
      };
      await updateTemplate(formData, data.id);
    };

    const templatePeriods = [
      { value: "SMS", name: "SMS" },
      { value: "VIBER", name: "VIBER" },
    ];

    watch(templateDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findTemplates();
    });

    return {
      dt,
      filters,
      isEdit,
      errors,
      loading,
      template,
      templateDialog,
      templates,
      templatePeriods,
      temlateHeaders,
      submitted,
      scrollHeight,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      create,
      reset,
      setActive,
      openNew,
      resetForm,
      closeDialog,
      editHandle,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>